@import "../scss/variables";

/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/

.app-sidebar {
  .mCSB_draggerContainer {
    right: -11px !important;
  }

  .ps__thumb-y {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .app {
    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 250px;
      }

      .app-sidebar__overlay {
        visibility: hidden;
      }
    }

    &.sidebar-mini.sidenav-toggled .app-sidebar__logo {
      width: 70px !important;
      border-top-right-radius: 40px;
    }
  }

  .app-sidebar__toggle {
    margin-left: 16.5rem;
  }

  .app-sidebar__logo {
    padding: 15px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    width: 250px;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
    background: $primary;
    border-top-right-radius: 70px;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    -o-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    flex-shrink: 0;
    align-items: center;
  }

  .app-sidebar3 {
    padding-top: 62px;
  }

  .app-header .header-brand, .sidebar-mini.sidenav-toggled .side-menu h3 {
    display: none;
  }
}

@media (max-width: 767px) {
  .app {
    overflow-x: hidden;

    .app-sidebar {
      left: -255px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }

    &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
      left: 0;
    }
  }

  .search-element {
    margin-left: 0;
  }

  .app-content .side-app {
    padding: 75px 0.85rem 0 0.85rem !important;
  }

  .app-sidebar__logo {
    display: none;
  }
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  overflow: hidden;

  .side-app {
    padding: 75px 30px 0 30px;
  }
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 250px;
  }
}

@media (max-width: 767px) {
  .app-content {
    min-width: 100%;
  }
}

@media (min-width: 992px) {
  .app-header {
    padding-right: 20px !important;
  }
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding: 0.75rem 0;
  background: #fff;
  box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  font-family: 'Niconne';
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus, &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}

.app-sidebar__toggle {
  color: #3a437f;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: 1;
  margin-top: 8px;
 
  svg {
    fill: #738298;
    margin-top: 6px;
  }

  &:focus, &:hover {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-left: 10px;
  }
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #8a98ac;
  width: 250px;
  height: 100%;
  z-index: 1000;
  background: $primary;
  -webkit-box-shadow: 0px 22px 0 21px #f1f4fb;
  box-shadow: -8px 12px 18px 0 #f1f4fb;
  border-right: 1px solid #eff0f6;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
  border-right: 0;
  border-top-right-radius: 70px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  width: 100%;
  padding: 25px 20px;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  .dropdown-menu {
    top: 10px !important;
  }
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;

  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }
}

.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
  padding: 12px 0;
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 22px 12px 15px;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  color: #a2a6cb;
  margin: 3px 0px 3px 12px;
  border-radius: 50px 0 0px 50px;

  &.active {
    text-decoration: none;
    color: #332a7c;

    .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      color: #332a7c;
      fill: #b3b8d1;
    }
  }
}

.slide-item.active {
  text-decoration: none;
  color: #b5c1d2;
}

.slide-menu a.active {
  color: #fff;
}

.side-menu__item {
  &:hover, &:focus {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
  }

  &:hover .side-menu__icon, &:focus .side-menu__icon {
    fill: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
  }
}

.slide-item {
  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #fff;
  }
}

.sub-side-menu__item {
  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #fff;
  }
}

.sub-slide-item {
  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #fff;
  }
}

.slide-menu li {
  position: relative;
}

.side-menu .slide {
  position: relative;
  padding: 0px;
}

.slide.is-expanded a {
  text-decoration: none;
}

.side-menu .side-menu__icon {
  color: #a2a6cb;
  fill: rgba(255, 255, 255, 0.1);
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.slide.is-expanded {
  .slide-menu {
    max-height: 200vh;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
  }

  .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.slide-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  padding: 0;
  font-size: .8rem !important;
  padding-left: 25px;
}

.slide.is-expanded .slide-menu {
  position: relative;
  margin: 12px 0 12px;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 14px 8px 15px;
  font-size: 13px;
  color: #7083ab;
  margin-left: 18px;
}

.slide-menu a:before {
  content: '\e048';
  margin-right: 8px;
  position: relative;
  font-family: 'typicons' !important;
  opacity: 0.6;
}

.slide-item .icon {
  margin-right: 5px;
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled {
    .app-sidebar__user-name, .app-sidebar__user-designation, .angle, .app-sidebar__user-name, .user-notification, .app-sidebar__user-name, .user-info {
      display: none;
    }

    .sidebar-mini.sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }

    .app-sidebar__user-name, .avatar-xl {
      width: 3rem !important;
      height: 3rem !important;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 0px !important;
    }

    .desktop-lgo {
      display: none;
    }

    .mobile-logo {
      display: block !important;
    }

    .app-sidebar__user .user-pic {
      margin-bottom: 0;
    }

    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }

    .app-sidebar__user {
      padding: 15px 0;
      margin-bottom: 0px;
      border-bottom: 1px solid rgba(225, 225, 225, 0.05);
    }

    .profile-img {
      top: 0px;
      right: 19px;
    }

    .app-sidebar3 {
      padding-top: 67px;
    }

    .app-content {
      margin-left: 70px;
    }

    .app-sidebar {
      left: 0;
      width: 70px;
      overflow: hidden;
      border-top-right-radius: 40px;
    }

    .side-menu__label {
      display: block;
      position: absolute;
      opacity: 0;
    }

    .slide .side-menu__label {
      border-bottom-right-radius: 0;
    }

    .slide-menu {
      position: absolute;
      left: 90px;
      min-width: 180px;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
      -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }
  }

  .app.sidebar-mini {
    &.sidenav-toggled .side-menu__item {
      display: block;
      padding: 12px 0;
      margin: 0 auto;
      text-align: center;
      border-left: 0;
      margin: 10px 0;
      margin-left: 10px;

      &.active {
        &:before, &:after {
          border-right: 20px solid #29327f;
        }
      }
    }

    &.sidenav-toggled1 .side-menu__item.active:after {
      border-right: 20px solid #29327f;
    }

    &.sidenav-toggled {
      .app-sidebar__logo {
        padding: 13px 15px;
      }

      .side-menu_label {
        display: block;
        font-size: 12px;
      }
    }
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }

  .sidebar-mini.sidenav-toggled {
    .app-sidebar__toggle {
      margin-left: 5rem;
    }

    .app-sidebar__logo .light-logo {
      display: block;
    }
  }

  .app.sidebar-mini {
    &.sidenav-toggled .side-menu .side-menu__icon {
      margin-right: 10px;
      margin-left: 0px;
    }

    &.sidenav-toggled1 {
      .app-sidebar {
        left: 0;
        width: 250px;
      }
	 .side-menu .side-menu__icon {
		margin-left: 10px;
	   }
      .app-sidebar__logo {
        width: 250px !important;

        .header-brand-img {
          &.desktop-lgo {
            display: block !important;
            text-align: center;
            margin: 0 auto;
          }

          &.mobile-logo {
            display: none !important;
          }
        }
      }
    }
  }

  .sidebar-mini.sidenav-toggled1 .side-menu__item {
    &.active:after, &:hover:after, &.active .shape2, &:hover .shape2 {
      top: 46px;
    }
  }

  .app.sidebar-mini.sidenav-toggled1 {
    .side-menu__item {
      display: flex;
      align-items: center;
      padding: 12px 22px 12px 5px;
      margin: 3px 0px 3px 12px;
      border-radius: 50px 0 0px 50px;
    }

    .side-menu__icon {
      margin-right: 10px !important;
    }

    .slide-menu {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 0.3s ease;
      -o-transition: max-height 0.3s ease;
      transition: max-height 0.3s ease;
      padding: 0;
      font-size: .8rem !important;
      padding-left: 25px;
      position: relative;
      left: 0;
      box-shadow: none;
      visibility: visible;
      opacity: inherit;
    }

    .slide.is-expanded .slide-menu {
      max-height: 100vh;
      -webkit-transition: max-height 0.3s ease;
      -o-transition: max-height 0.3s ease;
      transition: max-height 0.3s ease;
    }

    .side-menu__label {
      white-space: nowrap;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      opacity: 1;
      display: flex;
      position: relative;
    }

    .angle, .side-menu h3 {
      display: block;
    }

    .avatar-xl {
      width: 4rem !important;
      height: 4rem !important;
      line-height: 4rem;
      font-size: 1.75rem;
    }

    .app-sidebar__user {
      padding: 25px 20px;
    }

    .user-pic {
      margin-bottom: 0.8rem;
    }

    .user-info {
      display: block;
    }

    .app-sidebar__user-name {
      display: inline;
      font-size: 12px;
      font-weight: 400;
      line-height: inherit;
    }
  }

  /*sidenav-toggled1*/
}

.app.sidebar-mini .side-menu_label {
  display: none;
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.05);
  }

  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.user-info {
  .text-dark {
    color:$white!important;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0.2rem !important;
  }

  .text-muted {
    color: #8183b4 !important;
  }
}

.app-sidebar__logo {
  .header-brand1 {
    text-align: center;
    display: table;
    margin: 0 auto;
  }

  .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: none;
    margin-right: 0;
  }
}

.sidebar-mini.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.desktop-logo {
  display: none;
}

.app-sidebar__logo .header-brand-img {
  &.desktop-logo {
    display: none;
  }

  &.light-logo1 {
    display: block;
  }
}

.sidebar-mini.sidenav-toggled {
  .app-sidebar .app-sidebar__logo .header-brand-img {
    &.light-logo1 {
      display: none;
    }

    &.toggle-logo {
      display: none;
      margin-right: 0;
    }
  }

  .sidebar-navs {
    display: none;
  }

  .app-sidebar__toggle {
    display: block;
  }

  .mobile-header .app-sidebar__toggle {
    margin: 0;
    margin-top: 5px;
  }
}

.user-pic {
  margin-bottom: 0.8rem;
}

.sidebar-navs a {
  background: #25335f;
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
  color: #68798b !important;
  border-radius: 5px;
  padding: 0.8rem !important;
}

.app-sidebar .sidebar-navs {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.side-menu h3 {
  color: #6f749c;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 0.2rem;
  margin-top: 1.1rem;
  font-weight: 700;
  position: relative;
  padding: 0 20px 0 20px;
  margin-bottom: 12px;
  opacity: 0.8;
}

.side-menu__item.active {
  background: #f1f3fd;
  color: #332a7c;
}

.app.sidebar-mini {
  .footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  &.sidenav-toggled .footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}

@media (max-width: 767px) {
  .app.sidebar-mini .footer {
    padding: 1.25rem !important;
  }
}

.app-sidebar__logo .header-brand-img.toggle-logo, .app.sidebar-mini.sidenav-toggled1 .app-sidebar__logo .header-brand-img.light-logo {
  display: none;
}

.app-sidebar .app-sidebar__logo {
  .dark-logo, .mobile-logo, .darkmobile-logo {
    display: none;
  }
}

.side-menu__item.active {
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-right: 20px solid $primary;
    border-radius: 0px 0px 48px 0px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 46px;
    z-index: 0;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-right: 20px solid $primary;
    border-radius: 0px 48px 0px 0px;
  }

  .shape1 {
    background: #eff2f6;
    position: absolute;
    top: -30px;
    width: 20px;
    height: 60px;
    right: 0px;
  }

  .shape2 {
    background: #eff2f6;
    position: absolute;
    top: 46px;
    width: 20px;
    height: 30px;
    right: 0px;
  }
}

.sub-side-menu__item {
  padding-left: 34px !important;
  padding-right: 20px !important;
  height: 40px !important;
  display: block;
  line-height: 40px;
  color: #7083ab;
}

.sub-slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  height: 30px;
  padding: 0 0 0 25px !important;
  height: 28px !important;
  padding: 19px 0 19px 50px !important;
  font-size: 12px;
  color: #7083ab;
}

.sub-slide-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.9s ease;
  -o-transition: max-height 0.9s ease;
  transition: max-height 0.9s ease;
  padding: 0;
  font-size: .8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-angle {
  float: right;
  line-height: 40px !important;
}

.sub-slide.is-expanded .sub-slide-menu {
  max-height: 100vh;
  -webkit-transition: max-height 2s ease;
  -o-transition: max-height 2s ease;
  transition: max-height 2s ease;
}